export const header = {
  node: {
    elem: document.querySelector('header') || document.querySelector('.header'),
    fixedBack: '<span class="header--fixed-back" data-fixed-back aria-hidden="true"></span>',
    fixedClass: 'header--fixed',
    fixedBackData: '[data-fixed-back]',
    mobileToggle: document.querySelectorAll('[data-mobile-toggle]'),
    mobileMenuToggle: document.querySelectorAll('.js-mobileMenuList')
  },

  checkDocHeight: () => {
    return window.scrollY;
  },

  checkViewWidth: () => {
    return window.outerWidth;
  },

  checkBackElem: () => {
    return header.node.elem.querySelector(header.node.fixedBackData) ? true : false;
  },

  mobileToggle: () => {
    if( header.node.mobileToggle ) {
      header.node.mobileToggle.forEach( el => {
        el.addEventListener( 'click', function(e) {
          e.preventDefault();
          document.body.classList.toggle('open-nav')
        } );
      } );
    }
  },

  mobileMenuToggle: () => {
    if( header.node.mobileMenuToggle ) {
      header.node.mobileMenuToggle.forEach( el => {
        el.parentNode.addEventListener( 'click', function(e) {

          if (e.target.classList.contains('js-mobileMenuList') ) {
            e.preventDefault();
            this.closest('li').classList.toggle('open');

            return false;
          }
        } );
      });
    }
  },

  run: () => {
    let timer;
    window.addEventListener( 'scroll', function(e) {
      clearTimeout(timer);

      timer = setTimeout( () => {
        // Add On Mobile and Desctop
        if(
            (header.checkDocHeight() > 775 && header.checkViewWidth() >= 767)
            ||
            (header.checkDocHeight() > 450 && header.checkViewWidth() <= 767)
          )
        {
          // Add Fixed Class
          header.node.elem.classList.add(header.node.fixedClass);
          // Add Background Elem
          if( !header.checkBackElem()) header.node.elem.insertAdjacentHTML('beforeend', header.node.fixedBack);
        }else {
          // Remove Fixed Class
          header.node.elem.classList.remove(header.node.fixedClass);
          // Remove Background Elem
          if( header.checkBackElem()) header.node.elem.querySelector(header.node.fixedBackData).remove()
        }
      }, 10);
    } );
  },

  init: () => {
    header.run();
    header.mobileToggle();
    header.mobileMenuToggle();
  }
}