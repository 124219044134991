export const tables = {
  node: {
    item: '[data-table]',
    mobileToggle: '[data-engines-table-toggle]',
    dataScroll: document.querySelectorAll('[data-scroll-desc]')
  },

  tableBottomScroll: () => {

    let table = document.querySelectorAll(tables.node.item);

    if (table) {
      table.forEach(el => {
        // Init Custom Scrollbar
      if ($.fn.mCustomScrollbar) {
          $(el).mCustomScrollbar({
            axis: "x",
            scrollButtons: { enable: false },
            autoExpandScrollbar: false,
            scrollInertia: 60,
            mouseWheel: false,
          })
        }

      });
    }
  },

  mobileToggle: () => {
    let table = document.querySelectorAll(tables.node.mobileToggle);

    if (table) {
      table.forEach(el => {
        el.addEventListener('click', function() {
          this.nextElementSibling.classList.toggle('d-none');
        })
      });
    }
  },

  run: () => {
    tables.tableBottomScroll();
    tables.mobileToggle();
  },

  init: () => {
    tables.run();
  }
}