export const inputs = {
  node: {
    checkbox: '[data-checkbox]',
    select: '[data-custom-select]',
    applyTrigger: document.querySelectorAll('[data-target="#apply"]'),
    apply: '[data-apply-input]'
  },

  select: () => {

    let select = document.querySelectorAll(inputs.node.select);

    if(select) {
      select.forEach( el => {
        const text    = el.querySelector('[data-custom-select-text]');
        const list    = el.querySelector('[data-custom-select-list]');
        const inputs  = el.querySelectorAll('[type="radio"]');

        // Init Custom Scrollbar
        if( list && $.fn.mCustomScrollbar ) {
          $(list).mCustomScrollbar({
            right: '0',
            scrollButtons: { enable: false },
            scrollbarPosition: "inside",
            autoExpandScrollbar: false,
            scrollInertia: 60,
          })
        }

        // Input Events
        if( inputs && text ) {

          // Inputs
          inputs.forEach( elem => {
            elem.addEventListener( 'change', function(e) {
              e.preventDefault();

              text.querySelector('[data-custom-select-text-inner]').innerHTML = this.value;
              el.classList.remove('open');
            } );
          } );

          // Text Block
          text.addEventListener('click', function() {
            el.classList.toggle('open');
          })
        }

      } );

      if( $ ) {
        $(document).on('click', function(e) {
          
          if ( !$(e.target).closest('[data-custom-select]').length ) {
            select.forEach( el => {
              el.classList.remove('open');
            } );
          }
        })
      }
    }
  },

  checkbox: () => {
    const check = document.querySelectorAll(`${inputs.node.checkbox} input`);

    if (check) {
      check.forEach( el => {
        const form = el.closest('form');
        const btn = form ? form.querySelector('[type="submit"]') : false;

        if( btn ) {
          btn.disabled = true;

          el.addEventListener('change', function() {
            btn.disabled = this.checked ? false : true;
          })
        }

      } );
    }
  },
  
  apply: () => {
    if(inputs.node.applyTrigger) {
      inputs.node.applyTrigger.forEach( el => {
        el.addEventListener( 'click', function() {
          const elemInput = this.dataset.input;
          let input;

          if( elemInput && (input = document.getElementById(elemInput)) ) {
            input.checked = true;
          }
        } );
      } );
    }
  },

  init: () => {
    inputs.apply();
    inputs.select();
    inputs.checkbox();
  }
}