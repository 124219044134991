import Swiper from '../libs/swiper.min.js';

export const sliders = {

  node: {
    topSlider: {
      sld: document.querySelectorAll('[data-top-slider]'),
      title: '[data-top-slider-title]',
      img: '[data-top-slider-img]',
      thumbs: '[data-top-slider-thumbs]'
    },
    conceptSlider: {
      sld: document.querySelectorAll('[data-concept-slider]'),
      items: '[data-concept-slider-items]',
    },
    archiveSlider: {
      sld: document.querySelectorAll('[data-card-slider]'),
      dots: '[data-card-slider-dots]'
    },
    productsSlider: {
      sld: document.querySelectorAll('[data-products-slider]')
    },
    outlookSlider: {
      sld: document.querySelectorAll('[data-outlook-slider]')
    },
    enginesSlider: {
      sld: document.querySelectorAll('[data-engines-slider]'),
      items: '[data-engines-slider-items]',
      loaders: '[data-engines-loader]',
      tabs: '[data-engines-slider-tab]',
    },
  },

  topSlider: () => {
    if (sliders.node.topSlider.sld) {

      sliders.node.topSlider.sld.forEach(el => {
        const title = el.querySelector(sliders.node.topSlider.title);
        const img = el.querySelector(sliders.node.topSlider.img);
        const thumbs = el.querySelector(sliders.node.topSlider.thumbs);
        let titleSlider, thumbsSlider, imgSlider;
        let looped = 3;


        // Thumbs
        if (thumbs) {

          thumbsSlider = new Swiper(sliders.node.topSlider.thumbs, {
            loop: true,
            loopedSlides: looped,
            slidesPerView: 5,
            centeredSlides: true,
            allowTouchMove: false,
            breakpoints: {
              0: {
                slidesPerView: 3,
              },
              991: {
                slidesPerView: 5,
              }
            }
          });
        }

        // Image Slider
        if (img) {
          let prev = el.querySelector('[data-top-slider-nav="prev"]');
          let next = el.querySelector('[data-top-slider-nav="next"]');

          imgSlider = new Swiper(sliders.node.topSlider.img, {
            loop: true,
            loopedSlides: looped,
            slidesPerView: 3,
            centeredSlides: true,
            centeredSlidesBounds: true,
            thumbs: {
              swiper: thumbsSlider
            },
            navigation: {
              nextEl: next,
              prevEl: prev,
            },
            controller: {
              control: thumbsSlider
            },
            breakpoints: {
              0: {
                slidesPerView: 1,
              },
              767: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              }
            }
          });
        }


      });

      return true;
    }
  },

  conceptSlider: () => {
    if(sliders.node.conceptSlider.sld) {
      sliders.node.conceptSlider.sld.forEach( el => {
        const prev    = el.querySelector('[data-slider-nav="prev"]');
        const next    = el.querySelector('[data-slider-nav="next"]');
        const loader  = el.querySelector('[data-concept-slider-loader]');
        const current = el.querySelector('[data-concept-slider-current]');
        const exp       = el.querySelector(sliders.node.conceptSlider.items).dataset.conceptSliderItems ? el.querySelector(sliders.node.conceptSlider.items).dataset.conceptSliderItems : false;
        const slider  = exp ? sliders.node.conceptSlider.items.replace(']', `="${exp}"]`) : sliders.node.conceptSlider.items;
        const delay   = 7000;
        let timer;

        const sld = new Swiper(slider, {
          loop: true,
          slidesPerView: 1,
          disableOnInteraction: false,
          autoplay: {
            delay: delay,
          },
          autoplayDisableOnInteraction: false,
          on: {
            slideChangeTransitionStart: () => {
              loader.classList.remove('active');
            },
            slideChangeTransitionEnd: (sw) => {
              loader.classList.add('active');
              let totalSlides   = sw.slides.length - 2
              let currentCount  = (sw.activeIndex - 1) % (totalSlides) + 1;
              let total = currentCount === 0 ? totalSlides : currentCount;

              current.innerHTML = total >= 10 ? total : `0${total}`;
              sw.autoplay.start();
            }
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
        });

        next.addEventListener( 'click', function() {
          clearTimeout(timer);
          timer = setTimeout( () => {
            sld.autoplay.start();
          }, 300);
        } );
        prev.addEventListener( 'click', function() {
          clearTimeout(timer);
          timer = setTimeout( () => {
            sld.autoplay.start();
          }, 300);
        } );
      });
    }
  },

  outlookSlider: () => {
    if (sliders.node.outlookSlider.sld) {
      sliders.node.outlookSlider.sld.forEach(el => {
        const prev = document.querySelector('[data-slider-nav="prev"][data-outlook-slider-nav="' + el.dataset.outlookSlider + '"]');
        const next = document.querySelector('[data-slider-nav="next"][data-outlook-slider-nav="' + el.dataset.outlookSlider + '"]');

        let scrollBar;
        let scrollBarName = '[data-outlook-slider-scrollbar="' + el.dataset.outlookSlider + '"]';
        let sliderParams = {
          loop: true,
          slidesPerView: 3,
          grubCursor: true,
          spaceBetween: 21,
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
          breakpoints: {
            0: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            767: {
              slidesPerView: 3,
              spaceBetween: 30
            },
          }
        };
        let timer;
        let outlookSlider = null;

        if (scrollBar = document.querySelector(scrollBarName) ) {
          sliderParams.scrollbar = {
            el: scrollBarName,
            draggable: true,
            dragSize: 230,
            dragClass: 'outlook__drag',
            snapOnRelease: true,
          }
        }

        const initSlider = () => {
          outlookSlider = new Swiper(`[data-outlook-slider="${el.dataset.outlookSlider}"]`, sliderParams);
        }

        const destroySlider = () => {
          outlookSlider.destroy();
          outlookSlider = null;
        }

        initSlider();

        window.addEventListener('resize', () => {
          clearTimeout(timer);
          timer = setTimeout( () => {
            destroySlider();
            initSlider();
          }, 100);
        })
      });
    }
  },

  archiveSlider: () => {
    if(sliders.node.archiveSlider.sld) {
      sliders.node.archiveSlider.sld.forEach( el => {
        let bullets = el.querySelector(`[data-card-slider-dots="${el.dataset.cardSlider}"]`)

        let archiveSlider = new Swiper(`[data-card-slider="${el.dataset.cardSlider}"]`, {
          loop: false,
          slidesPerView: 1,
          pagination: {
            el: bullets,
            clickable: true,
            type: 'bullets',
            bulletClass: 'archive__list-dot',
            bulletActiveClass: 'active',
          },
          a11y: false
        } )
      } );
    }
  },

  enginesSlider: () => {
    if( sliders.node.enginesSlider.sld ) {
      sliders.node.enginesSlider.sld.forEach( el => {
        const prev  = el.querySelector('[data-slider-nav="prev"]');
        const next  = el.querySelector('[data-slider-nav="next"]');
        const items = el.querySelector(sliders.node.enginesSlider.items);
        const tabs  = el.querySelectorAll(sliders.node.enginesSlider.tabs);
        const tableToggle = el.querySelectorAll('[data-engines-table-toggle]');
        let timer;

        const enginesSlider = new Swiper( `.${items.classList[0]}`, {
          loop: true,
          slidesPerView: 1,
          autoplay: {
            delay: 7000,
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
          on: {
            slideChangeTransitionStart: function (sw) {
              let activeIndex = sw.activeIndex;
              let activeTab   = el.querySelector(`[data-engines-slider-tab][data-index="${activeIndex - 1}"]`);

              tabs.forEach( elem => {
                elem.classList.remove('active');
              });
              if(activeTab) {
                activeTab.classList.add('active');
              }else {
                el.querySelector(`[data-engines-slider-tab][data-index="0"]`).classList.add('active');
              }
              sw.autoplay.start();
              
              if (tableToggle) {
                tableToggle.forEach(el => {
                  el.nextElementSibling.classList.add('d-none')
                });
              }
            },
          },
        } );

        tabs.forEach( el => {
          el.addEventListener( 'click', function(e) {
            e.preventDefault();
            let tabIndex = this.dataset.index ? Number(this.dataset.index) : '';

            enginesSlider.slideToLoop(tabIndex, 500, true)
          } );
        });

        next.addEventListener('click', function () {
          clearTimeout(timer);
          setTimeout(() => {
            enginesSlider.autoplay.start();
          }, 100);
        });
        prev.addEventListener('click', function () {
          clearTimeout(timer);
          setTimeout(() => {
            enginesSlider.autoplay.start();
          }, 100);
        });

        if(tableToggle) {
          tableToggle.forEach( el => {
            el.addEventListener( 'click', function() {
              enginesSlider.autoplay.stop();
            } );
          } );
        }


      });
    }
  },

  productsSlider: () => {
    if(sliders.node.productsSlider.sld) {
      sliders.node.productsSlider.sld.forEach( el => {
        const prev = el.querySelector('[data-slider-nav="prev"]');
        const next = el.querySelector('[data-slider-nav="next"]');

        const productsSlider = new Swiper('[data-products-slider]', {
          loop: true,
          slidesPerView: 1,
          disableOnInteraction: false,
          autoplay: {
            delay: 5000,
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
        })
      } )
    }
  },

  run: () => {
    sliders.topSlider();
    sliders.archiveSlider();
    sliders.outlookSlider();
    sliders.conceptSlider();
    sliders.productsSlider();
    sliders.enginesSlider();
  },

  init: () => {
    sliders.run();
  }
}