import './libs/focus-visible.min.js';
import './libs/custom-scroll.min.js';
import { canUseWebP as webp } from './components/webp.js';
import { header } from './components/header.js';
import { sliders } from './components/sliders.js';
import { inputs } from './components/inputs.js';
import { features } from './components/features.js';
import { hovers } from './components/hover.js';
import { toggle } from './components/toggle.js';
import { upload } from './components/upload.js';
import { tables } from './components/tables.js';

window.addEventListener('DOMContentLoaded', () => {
  header.init();
  sliders.init();
  inputs.init();
  features.init();
  hovers.init();
  toggle.init();
  upload.init();
  tables.init();
  webp.init();
});
