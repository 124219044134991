export const toggle = {

  node: {
    items: document.querySelectorAll('[data-apply]'),
    desc: '[data-apply-desc]',
    tabs: document.querySelectorAll('[data-hidden-trigger-tab]'),
    strollTarget: document.querySelectorAll('[data-target]')
  },

  tabToggle: () => {
    if(toggle.node.tabs) {
      toggle.node.tabs.forEach( el => {
        el.addEventListener( 'click', function() {
          const parent = this.closest('[data-tabs]');
          const elems = parent.querySelectorAll('[data-tab]');
          
          elems.forEach( elem => {
            elem.classList.add('open');
          } );

          this.style.display = 'none';
        } );
      });
    }
  },

  scrollTop: () => {
    if (toggle.node.strollTarget && $ ) {
      $(toggle.node.strollTarget).each( function() {
        $(this).on('click', function(e) {
          e.preventDefault();
          const param = $(this).data('target')
          const elem  = $(param).length ? $(param) : false;

          if(elem) {
            let pos = elem.offset().top;
            $('html, body').animate({
              scrollTop: pos - 100
            }).removeClass('open-nav');
          }

        })
      } );

    }
  },

  run: () => {
    toggle.tabToggle();
    toggle.scrollTop();

    if (toggle.node.items) {
      toggle.node.items.forEach( el => {
        
        el.querySelector('[data-apply-toggle]').addEventListener( 'click', function(e) {
          e.preventDefault();

          
          if( $.fn ) {
            $(el.querySelector('[data-apply-desc]')).stop(true, true).slideToggle().closest('[data-apply]').toggleClass('open').siblings().removeClass('open').find(toggle.node.desc).slideUp();
          }else {
            el.classList.toggle('open-block')
          }
        } );
      } );
    }
  },

  init: () => {
    toggle.run();
  }
}