export const upload = {
  node: {
    item: document.querySelectorAll('[data-input-upload'),
    text: '[data-input-upload-text]'
  },

  run: () => {
    if(upload.node.item) {

      Array.prototype.forEach.call(upload.node.item, function (label) {
        let input     = label.querySelector('input'),
            labelVal  = label.querySelector(upload.node.text).innerText;
  
        input.addEventListener('change', function (e) {
          let countFiles = '';
          if (this.files && this.files.length >= 1)
            countFiles = this.files.length;
  
          if (countFiles)
            label.querySelector(upload.node.text).innerText = this.files[0].name;
          else
            label.querySelector(upload.node.text).innerText = labelVal;
        });
      });
    }
  },
  init: () => {
    upload.run();
  }
}