export const features = {
  node: {
    items: document.querySelectorAll('[data-features]'),
  },

  event: (elem) => {
    const activeClass = 'active';

    elem.classList.add(activeClass);
    for (let sibling of elem.parentNode.children) {
      if (sibling !== elem) sibling.classList.remove(activeClass);
    }
  },

  run: () => {
    if( features.node.items ) {

      features.node.items.forEach( el => {
        el.addEventListener( 'mouseover', function() {
          features.event(el);
        })

        // For Mobile
        el.addEventListener( 'click', function() {
          features.event(el);
        })
      } );
    }
  },

  init: () => {
    features.run();
  }
}