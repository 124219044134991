const canUseWebP = {

  check: () => {

    let elem = document.createElement('canvas');
  
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
  
    return false;
  },

  run: () => {
    if (canUseWebP.check()) {
      document.body.classList.add('webp')
    }
  },

  init: () => {
    canUseWebP.run();
  }
}

export {canUseWebP};