export const hovers = {
  node: {
    product: document.querySelectorAll('[data-product-app]')
  },

  event: function() {
    let elem        = this.parentNode.querySelector('img');
    let currentUrl  = elem ? elem.src : this.dataset.prev;
    let presBlock   = document.querySelector(`[data-product-app-pres="${this.dataset.productApp}"]`);
    presBlock.style.backgroundImage = currentUrl ? `url(${currentUrl})` : '';
  },

  run: () => {
    if( hovers.node.product ) {
      hovers.node.product.forEach( el => {
        el.addEventListener('mouseover', hovers.event)
        el.addEventListener('focus', hovers.event)
        el.addEventListener( 'click', function() {
          
          if($) {
            $(this).toggleClass('open').closest('.products__app-item').siblings().find('[data-product-app]').removeClass('open');
          }else {
            console.log('JQuery is not defined');
          }
        } );
      } )
    }
  },

  init: () => {
    hovers.run();
  }
}